<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">База задач </h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              type="text"
              @keyup="searchByName"
              placeholder="Фильтр по олимпиадам ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <Multiselect
              :canClear="false"
              @change="onFilterByType"
              style="width: 250px"
              v-model="searchByType"
              :options="types"/>
        </div>
      </form>
      <button
          style="margin-left: auto"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/contests/new`)">
        <i class="fas fa-plus mr-2"></i> Новая олимпиада
      </button>
    </div>
    <table-list :columns="columns" :actions="actions" :data="contests"/>
    <table-pagination v-if="totalPages > 0" :total="totalPages" @on-page="loadPage"/>
  </div>
</template>

<script>
import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination'
import contestService from '@/services/contest.service'
import Multiselect from '@vueform/multiselect'
import {nextTick, onMounted, ref, watchEffect} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'ContestList',
  components: {
    TableList,
    TablePagination,
    Multiselect
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    const contests = ref([])
    const totalPages = ref(0)
    const searchByStatus = ref(route.query.type || 'all')
    const types = [
      {label: 'Все', value: 'all'},
      {label: 'Черновик', value: 'draft'},
      {label: 'Активные', value: 'active'},
      {label: 'Архив', value: 'archive'}
    ]
    const defaultPerPage = ref(50)

    const formatStatus = (contest) => {
      if (contest.status === 'DRAFT') {
        return 'Черновик'
      } else if (contest.status === 'ACTIVE') {
        return 'Проводится'
      } else {
        return 'Архив'
      }
    }

    const getById = (id) => {
      return contests.value.find(contest => contest.id === id)
    }

    const doFinish = (ref) => {
      const contest = getById(ref.id)
      if (confirm(`Подвести итоги олимпиады ${contest.name}?`)) {
        contestService
            .summarizing(contest.id)
            .then(() => {
              router.push(`/my/${contest.id}/result`)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const doDelete = (ref) => {
      const contest = getById(ref.id)
      if (confirm(`Удалить олимпиаду ${contest.name}?`)) {
        contestService
            .delete(contest.id)
            .then(() => {
              search(route.query)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const tableProps = {
      columns: [
        {
          title: 'ID',
          value: (contest) => {
            return contest.id
          }
        },
        {
          title: 'Название',
          value: (contest) => {
            return contest.name
          }
        },
        {
          title: 'Дата проведения',
          value: (contest) => {
            return contest.startedAt
          }
        },
        {
          title: 'Ссылка',
          externalUrl: true,
          value: (contest) => {
            return `https://ruchess.agileminds.ru/join/${contest.hash}`
          },
          href: (contest) => {
            return `/join/${contest.hash}`
          }
        },
        {
          title: 'Статус',
          value: formatStatus,
          badge: true
        }
      ],
      actions: {
        edit: {
          title: 'Редактировать',
          function: (contest) => {
            router.push(`/my/contests/${contest.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: doDelete
        },
        finish: {
          title: 'Подвести итоги',
          function: doFinish
        }
      }
    }

    onMounted(() => {
      watchEffect(() => {
        search(route.query)
      })
    })

    const search = (query) => {
      if (searchByStatus.value !== 'all') {
        query.status = searchByStatus.value.toUpperCase()
      }
      if (!query.size) {
        query.size = defaultPerPage.value
      }
      query.sort = 'id'
      contestService.search(query)
          .then(response => {
            contests.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const buildQuery = () => {
      const query = {}
      const filter = searchQuery.value
      if (filter.length) {
        query.filter = filter
      }

      const type = searchByStatus.value
      if (type && type.length && type !== 'all') {
        query.type = type
      }
      return query
    }

    const onFilterByType = () => {
      nextTick(() => {
        router.push({
          path: '/my/contests',
          query: buildQuery()
        })
      })
    }

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {
        router.push({
          path: '/my/contests',
          query: buildQuery()
        })
      }, 500)
    }
    const searchProps = {searchQuery, searchByName}

    const loadPage = (page) => {
      search({
        page: page,
        filter: searchQuery.value.toLowerCase().trim()
      })
    }
    const paginationProps = {
      totalPages, loadPage
    }

    search({})

    return {
      contests,
      searchByType: searchByStatus,
      types,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onDelete: doDelete,
      onFilterByType
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>