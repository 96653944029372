<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold mt-6 mb-6">
              <bold v-if="errorMessage !== 'expired'">Авторизация</bold>
              <bold v-else>Сессия истекла</bold>
            </div>
            <div v-if="authError" class="text-red-500 text-center mb-3 font-bold mt-6 mb-6">
              <bold>Неправильный email или пароль</bold>
            </div>
            <form @submit.prevent="signIn">
              <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Email
                </label>
                <input
                    v-model="email"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Пароль
                </label>
                <input
                    v-model="password"
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                />
              </div>

              <div class="text-center mt-6">
                <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit">
                  Войти
                </button>
              </div>
            </form>
          </div>
        </div>
        <!--        <div class="flex flex-wrap mt-6 relative">-->
        <!--          <div class="w-1/2">-->
        <!--            <a href="javascript:void(0)" class="text-blueGray-200">-->
        <!--              <small>Забыли пароль?</small>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import authService from '@/services/auth.service'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'LoginPage',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const email = ref('')
    const password = ref('')
    const authError = ref(false)
    const errorMessage = ref(route.query.error_msg)

    const signIn = () => {

      authError.value = false
      errorMessage.value = ''

      authService.signIn({
        username: email.value,
        password: password.value
      })
          .then((signInResponse) => {
            localStorage.setItem('auth', JSON.stringify({
              token: signInResponse.data
            }))

            authService.status()
                .then((response) => {
                  if (response.data.id > 0) {
                    localStorage.setItem('account', JSON.stringify(response.data))

                    setTimeout(() => {
                      router.push({path: '/my'})
                    }, 10)
                  }
                  authError.value = true
                })
                .catch(() => {
                  authError.value = true
                })

            router.push({path: '/my'})
          })
          .catch(() => {
            authError.value = true
          })
    }

    authService.status()
        .then((response) => {
          if (response.data.id > 0) {
            localStorage.setItem('account', JSON.stringify(response.data))

            router.push({path: '/my'})
          }
        })
        .catch((error) => {
          console.error(error)
        })

    return {
      email,
      password,
      authError,
      errorMessage,
      signIn
    }
  }
}
</script>
