<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <GeoNavigation/>
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">Школы</h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              type="text"
              @keyup="searchByName"
              placeholder="Фильтр по школам ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <Multiselect
              :canClear="false"
              placeholder="Федеральный округ"
              @change="onParentRegion"
              style="width: 250px"
              v-model="parentId"
              :options="parentRegions"
              ref="parentIdSelect"/>
        </div>
      </form>
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <Multiselect
              :canClear="true"
              placeholder="Регион"
              @change="onRegion"
              style="width: 250px"
              v-model="regionId"
              :options="regions"
              ref="regionIdSelect"/>
        </div>
      </form>
      <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/schools/import`)"
      >
        <i class="fas fa-database mr-2"></i> Импорт школ
      </button>
      <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/schools/new`)"
      >
        <i class="fas fa-plus mr-2"></i> Новая школа
      </button>
    </div>
    <table-list :columns="columns" :actions="actions" :data="schools"/>
    <table-pagination :total="totalPages" @on-page="loadPage"/>
  </div>
</template>
<script>

import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination.vue'
import SchoolService from '@/services/api/school.service'
import regionService from '@/services/region.service'
import GeoNavigation from '@/views/my/geo/GeoNavigation'
import Multiselect from '@vueform/multiselect'
import {nextTick, onMounted, ref, watchEffect} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'SchoolList',
  components: {TableList, TablePagination, GeoNavigation, Multiselect},
  setup() {

    const route = useRoute()
    const router = useRouter()
    const schools = ref([])
    const defaultPerPage = ref(100)
    const parentId = ref(0)
    const regionId = ref(0)

    const parentIdSelect = ref(null)
    const regionIdSelect = ref(null)

    const parentRegions = ref([])
    const regions = ref([])

    const onRegion = () => {
      nextTick(() => {
        search({})
      })
    }

    const onParentRegion = () => {
      regionIdSelect.value.clear()

      nextTick(() => {
        regionService.search({
          parent: parentId.value,
          size: 1000
        }).then(response => {
          regions.value = response.data.content.map(r => {
            return {
              label: r.name,
              value: r.id
            }
          })
        })

        search({})
      })
    }

    const getById = (id) => {
      return schools.value.find(school => school.id === id)
    }

    const onDelete = (ref) => {
      const school = getById(ref.id)
      if (confirm(`Удалить школу ${school.name}?`)) {
        SchoolService
            .delete(school.id)
            .then(() => {
              schools.value = schools.value.filter(s => {
                return s.id !== ref.id
              })
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const search = (query) => {
      if (!query.size) {
        query.size = defaultPerPage.value
      }

      if (parentId.value) {
        query.parent = parentId.value
      }

      if (regionId.value) {
        query.region = regionId.value
      }

      query.sort = 'name'
      SchoolService.search(query)
          .then(response => {
            schools.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const tableProps = {
      actions: {
        edit: {
          title: 'Редактировать',
          function: (school) => {
            router.push(`/my/schools/${school.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: onDelete
        }
      },
      columns: [
        {
          title: 'ID',
          value: (schools) => {
            return schools.id
          }
        },
        {
          title: 'Официальное название',
          value: (school) => {
            return school.name
          }
        },
        {
          title: 'Регион',
          value: (school) => {
            return school.region?.name
          }
        }
      ]
    }

    onMounted(() => {
      watchEffect(() => {
        search(route.query)
      })
    })

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {
        router.push({
          path: '/my/schools',
          query: {filter: searchQuery.value.toLowerCase().trim()}
        })
      }, 500)
    }
    const searchProps = {searchQuery, searchByName}

    const totalPages = ref(1)
    const loadPage = (page) => {
      search({
        filter: route.query?.filter,
        page: page - 1
      })
    }
    const paginationProps = {
      totalPages, loadPage
    }

    regionService.search({
      size: 1000
    }).then(response => {
      parentRegions.value = response.data.content.map(r => {
        return {
          label: r.name,
          value: r.id
        }
      })

      search({})
    })


    return {
      schools,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onDelete,
      parentId,
      regionId,
      parentRegions,
      regions,
      parentIdSelect,
      regionIdSelect,
      onParentRegion,
      onRegion
    }
  }

}
</script>
