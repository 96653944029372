<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <GeoNavigation/>
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">Регионы</h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
              placeholder="Фильтр по регионам ..."
              type="text"
              @keyup="searchByName"
          />
        </div>
      </form>
      <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          style="margin-left: auto"
          type="button"
          @click="$router.push(newUrl)">
        <i class="fas fa-plus mr-2"></i> Новый регион
      </button>
    </div>
    <table-list :actions="actions" :columns="columns" :data="regions"/>
    <table-pagination :total="totalPages" @on-page="loadPage"/>
  </div>
</template>
<script>

import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination.vue'
import RegionService from '@/services/region.service'
import GeoNavigation from '@/views/my/geo/GeoNavigation'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'RegionList',
  components: {TableList, TablePagination, GeoNavigation},
  setup() {

    const route = useRoute()
    const router = useRouter()
    const regions = ref([])
    const newUrl = ref(`/my/regions/new`)
    const defaultPerPage = ref(100)

    if (route.query.parent) {
      const parentId = parseInt(route.query.parent)
      if (parentId) {
        newUrl.value = `/my/regions/new?parent=${parentId}`
      }
    }

    const getById = (id) => {
      return regions.value.find(region => region.id === id)
    }

    const onDelete = (ref) => {
      const region = getById(ref.id)
      if (confirm(`Удалить регион ${region.name}?`)) {
        RegionService
            .delete(region.id)
            .then(() => {
              search(route.query)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const search = (query) => {
      if (!query.size) {
        query.size = defaultPerPage.value
      }
      query.sort = 'name'
      RegionService.search(query)
          .then(response => {
            regions.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const tableProps = {
      actions: {
        edit: {
          title: 'Редактировать',
          function: (region) => {
            router.push(`/my/regions/${region.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: onDelete
        }
      },
      columns: null
    }

    if (route.query && route.query.parent) {
      tableProps.columns = [
        {
          title: 'ID',
          value: (region) => {
            return region.id
          }
        },
        {
          title: 'Название',
          value: (region) => {
            return region.name
          }
        },
        {
          title: 'Федеральный округ',
          value: (region) => {
            return region.parent?.name
          }
        },
        {
          title: 'ШвШ',
          value: (region) => {
            return region.program ? 'да' : 'нет'
          }
        }
      ]

    } else {
      tableProps.columns = [
        {
          title: 'ID',
          value: (region) => {
            return region.id
          }
        },
        {
          title: 'Название',
          internalUrl: true,
          value: (region) => {
            return region.name
          },
          href: (region) => {
            return `/my/regions?parent=${region.id}`
          }
        },
        {
          title: 'ШвШ',
          value: (region) => {
            return region.program ? 'да' : 'нет'
          }
        }
      ]
    }

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {

        const query = {
          filter: searchQuery.value.toLowerCase().trim()
        }

        if (route.query.parent) {
          const parentId = parseInt(route.query.parent)
          if (parentId) {
            query.parent = parentId
          }
        }

        router.push({
          path: '/my/regions',
          query
        })
        setTimeout(() => {
          search(route.query)
        }, 10)
      }, 200)
    }
    const searchProps = {searchQuery, searchByName}

    const totalPages = ref(1)
    const loadPage = (page) => {
      search({
        page: page,
        filter: searchQuery.value.toLowerCase().trim()
      })
    }
    const paginationProps = {
      totalPages, loadPage
    }

    const query = {}
    if (route.query.parent) {
      const parentId = parseInt(route.query.parent)
      if (parentId) {
        query.parent = parentId
      }
    }

    if (!query.parent) {
      query.root = true
    }

    search(query)

    return {
      regions,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onDelete,
      newUrl
    }
  }

}
</script>
