<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">Команды</h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              type="text"
              @keyup="searchByName"
              placeholder="Фильтр по городам ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <button
          style="margin-left: auto"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/teams/new`)">
        <i class="fas fa-plus mr-2"></i> Новая команда
      </button>
    </div>
    <table-list :columns="columns" :actions="actions" :data="teams"/>
    <table-pagination :total="totalPages" @on-page="loadPage"/>
  </div>
</template>
<script>

import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination.vue'
import teamService from '@/services/team.service'
import {onMounted, ref, watchEffect} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'TeamList',
  components: {TableList, TablePagination},
  setup() {

    const route = useRoute()
    const router = useRouter()
    const teams = ref([])
    const defaultPerPage = ref(50)

    const getById = (id) => {
      return teams.value.find(team => team.id === id)
    }

    const onDelete = (ref) => {
      const team = getById(ref.id)
      if (confirm(`Удалить команду ${team.name}?`)) {
        teamService
            .delete(team.id)
            .then(() => {
              search(route.query)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const search = (query) => {
      if (!query.size) {
        query.size = defaultPerPage.value
      }
      query.sort = 'name'
      teamService.search(query)
          .then(response => {
            teams.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const tableProps = {
      actions: {
        edit: {
          title: 'Редактировать',
          function: (team) => {
            router.push(`/my/teams/${team.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: onDelete
        }
      },
      columns: [
        {
          title: 'ID',
          value: (team) => {
            return team.id
          }
        },
        {
          title: 'Название',
          value: (team) => {
            return team.name
          }
        }
      ]
    }

    onMounted(() => {
      watchEffect(() => {
        search(route.query)
      })
    })

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {
        router.push({
          path: '/my/teams',
          query: {filter: searchQuery.value.toLowerCase().trim()}
        })
      }, 500)
    }
    const searchProps = {searchQuery, searchByName}

    const totalPages = ref(1)
    const loadPage = (page) => {
      search({page: page})
    }
    const paginationProps = {
      totalPages, loadPage
    }

    search({})

    return {
      teams,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onDelete
    }
  }

}
</script>
