<template>
  <div v-if="total > 1" class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
      >
        <div class="py-2">
          <nav class="block">
            <ul class="flex pl-0 rounded list-none flex-wrap">
              <li>
                <a @click="getFirstPages()"
                   :class="{'cursor-pointer border-emerald-200 text-white bg-emerald-200': pages[0] !== 1,
                   'border-emerald-500 bg-white text-emerald-500': pages[0] === 1}"
                   class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  <i class="fas fa-chevron-left -ml-px"></i>
                  <i class="fas fa-chevron-left -ml-px"></i>
                </a>
              </li>
              <li>
                <a @click="getPreviousPages()"
                   :class="{'cursor-pointer border-emerald-200 text-white bg-emerald-200': pages[0] !== 1,
                   'border-emerald-500 bg-white text-emerald-500': pages[0] === 1}"
                   class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  <i class="fas fa-chevron-left -ml-px"></i>
                </a>
              </li>
              <li v-for="page in pages" :key="page">
                <a @click="selectPage(page)"
                   :class="{'border-emerald-500 text-white bg-emerald-500': current === page,
                   'cursor-pointer border-emerald-500 bg-white text-emerald-500': current !== page}"
                   class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  {{ page }}
                </a>
              </li>
              <li>
                <a @click="getNextPages()"
                   :class="{'cursor-pointer border-emerald-200 text-white bg-emerald-200': pages[pages.length - 1] < total,
                   'border-emerald-500 bg-white text-emerald-500': pages[pages.length - 1] === total}"
                   class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  <i class="fas fa-chevron-right -mr-px"></i>
                </a>
              </li>
              <li>
                <a @click="getLastPages()"
                   :class="{'cursor-pointer border-emerald-200 text-white bg-emerald-200': pages[pages.length - 1] < total,
                   'border-emerald-500 bg-white text-emerald-500': pages[pages.length - 1] === total}"
                   class="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  <i class="fas fa-chevron-right -mr-px"></i>
                  <i class="fas fa-chevron-right -mr-px"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, watchEffect} from 'vue'

export default {
  name: 'TablePagination',
  props: ['total'],
  setup(props, {emit}) {
    const current = ref(1)
    const pages = ref([])
    const length = ref(5)

    const selectPage = (index) => {
      if (index !== current.value) {
        current.value = index
        emit('onPage', index)
      }
    }

    const getNextPages = () => {
      if (pages.value.length === length.value && pages.value[length.value - 1] < props.total) {
        const last = pages.value[length.value - 1] + length.value <= props.total ?
            pages.value[length.value - 1] + length.value : props.total
        const start = pages.value[0] + length.value
        pages.value = arrayRange(start, last)

        selectPage(start)
      }
    }

    const getPreviousPages = () => {
      if (pages.value[0] !== 1) {
        const last = pages.value[0] - 1
        const start = pages.value[0] - length.value
        pages.value = arrayRange(start, last)

        selectPage(start)
      }
    }

    const getLastPages = () => {
      if (pages.value.length === length.value && pages.value[length.value - 1] < props.total) {
        const start = Math.floor(props.total / length.value) * length.value + 1
        pages.value = arrayRange(start, props.total)

        selectPage(start)
      }
    }

    const getFirstPages = () => {
      if (pages.value[0] !== 1) {
        pages.value = arrayRange(1, length.value)

        selectPage(1)
      }
    }

    const arrayRange = (start, end) => {
      return Array.from(
          {length: (end - start) + 1},
          (value, index) => start + index
      )
    }

    onMounted(() => {
      watchEffect(() => {
        length.value = props.total
        pages.value = arrayRange(1, length.value)
      })
    })

    return {
      current,
      pages,
      length,
      selectPage,
      getNextPages,
      getPreviousPages,
      getLastPages,
      getFirstPages,
      arrayRange
    }
  }
}
</script>
